body {
  margin: 0;
  background-color: #1c1c1c;
  color: #fff;
  font-family: 'DM-Sans';
}

a {
  text-decoration: none;
}
a:link, a:visited {
  color: green;
}
a:hover {
  color: purple;
}
