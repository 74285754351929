
.popupBackground {
    width: 100vw;
    height:100vh;
    background-color: #141414cb;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupContainer {
    width: 500px;
    height: auto;
    /* height: 500px; */
    border-radius: 12px;
    background-color: #1C1C1C;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.popupTitle {
    display: inline-block;
    text-align: center;
    margin-top: 5px;
    font-size: 1.7rem;
}

.closeBtn {
    display: flex;
    justify-content: flex-end;
}

.closeBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    color: white;
    cursor: pointer;
}

.popupBodyCenter {
    margin-top: 15px;
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    text-align: center;
}

.popupBodyLeft {
    margin-top: 15px;
    flex: 50%;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 1.1rem;
    text-align: left;
}

.acceptBtn {
    padding: 10px 24px;
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: 16px;
    color: #fff;
    font-family: 'DM Sans';
    cursor: pointer;
    background-color: #055805;
}

.cancelBtn {
    padding: 10px 24px;
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: 16px;
    color: #fff;
    font-family: 'DM Sans';
    cursor: pointer;
    background-color: #ff0000;
}

.react-confirm-alert-overlay {
    background: #141414db !important;
  }